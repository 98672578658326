import Button from 'components/Button';

interface LoginMessageProps {
  email: string;
  onResendLinkClick: () => void;
}

const LoginMessage = ({ email, onResendLinkClick }: LoginMessageProps) => (
  <div className="my-4 flex flex-col gap-6 text-center">
    <h1 className="text-xl">
      We&apos;ve sent a login link to <span className="break-words">{email}</span>
    </h1>
    <p className="text-xs font-light">
      Please check your inbox. If you haven&apos;t received an email with your login link, check the
      email address you entered and try again.
    </p>
    <Button className="w-full" type="submit" variant="outlined" onClick={onResendLinkClick}>
      Resend link
    </Button>
  </div>
);

export default LoginMessage;

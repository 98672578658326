'use client';

import { useForm } from 'react-hook-form';
import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'next/navigation';

import Button from 'components/Button';
import { authorizeMagicLink } from 'lib/AgileApiClient';
import { showToast } from 'utils/showToast';

import LoginMessage from './LoginMessage';

interface FormValues {
  email: string;
}

const LoginForm = () => {
  const {
    register,
    handleSubmit,
    getValues,
    reset,
    formState: { errors, isSubmitted, isSubmitting, isValid },
  } = useForm<FormValues>({
    mode: 'onBlur',
    defaultValues: {
      email: '',
    },
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const email = useMemo(() => getValues('email'), [isSubmitted]);
  const searchParams = useSearchParams();
  const isSessionExpired = searchParams.get('expired') === 'true';

  useEffect(() => {
    if (isSessionExpired) {
      showToast('error', 'Your session has expired. Please log in to continue.');
    }
  }, [isSessionExpired]);

  const onSubmit = handleSubmit(async ({ email }: FormValues) => {
    await authorizeMagicLink(email);
  });

  const onResendLinkClick = () => {
    reset({ email });
  };

  if (isSubmitted) {
    return <LoginMessage email={email} onResendLinkClick={onResendLinkClick} />;
  }

  return (
    <form className="flex flex-col" onSubmit={onSubmit}>
      <h1 className="mb-4 text-center text-xl">Access Your Account</h1>
      <p className="mb-8 text-center text-xs text-gray-500">
        Enter your email address to receive a unique link to login to your Patient Portal and manage
        your prescriptions.
      </p>
      <input
        autoComplete="on"
        className="min-h-[2rem] w-full rounded border border-solid border-black-500 p-1 text-sm font-light 2xl:text-xs"
        id="email"
        placeholder="Your email"
        {...register('email', {
          required: 'Required',
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: 'invalid email address',
          },
          shouldUnregister: true,
        })}
        type="email"
      />
      {errors.email && <p className="mt-4 text-xs text-red-500">{errors.email.message}</p>}
      <Button
        className="mt-8"
        disabled={!isValid || isSubmitting}
        isLoading={isSubmitting}
        type="submit"
        variant="primary"
      >
        {isSubmitting ? 'Loading...' : 'Send Login Link'}
      </Button>
    </form>
  );
};

export default LoginForm;
